// subtext in dropdown menus
.MuiSelect-root {
  sub {
    display: none;
  }
}

// dropdown menus
.MuiMenu-paper {
  border-radius: 1rem !important;

  .MuiListItem-root {
    @include setSpace($padding-top: 10px, $padding-bottom: 10px);
    @include setFont($size: 16px);
    white-space: initial;

    p {
      white-space: pre-line;

      sub {
        display: block;
      }
    }
  }

  @media (max-width: 992px) {
    .MuiListItem-root {
      @include setSpace($padding-top: 6px, $padding-bottom: 6px);
    }
  }
}
