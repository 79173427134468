@import "./styles/variables";
@import "./styles/base/fonts";
@import "./styles/base/mixins";

// Containers
@import "container/style";
@import "container/PageNotFound/style";
@import "container/AppStepsContainer/style";

// Components
@import "components/Loader/style";
@import "components/Start/style";
@import "components/KnowYourImpact/style";
@import "components/ImpactAreas/style";
@import "components/KnowYourImpact/style";
@import "components/Results/style";
@import "components/NavBar/style";
@import "components/NoticeDialog/style";
@import "components/InvestGoals/style";
@import "components/RiskTolerance/style";
@import "components/ImpactDetails/style";
@import "components/Projection/style";

.App {
  margin: 0 auto;

  .bg-blue {
    background-color: $background-color-1;
  }

  .text-center {
    text-align: center;
  }

  .style-as-link {
    @include setFont($size: 16px, $color: $main-blue);
    text-decoration: underline;
    cursor: pointer;
  }

  .recommended-badge {
    @include setFont($size: 10px, $color: $main-blue, $weight: bold);
    @include flex(row, center, center);
    width: 84px;
    height: 28px;
    border-radius: 1px;
    border: solid 3px $main-blue;
  }
}

#root.App {
  .checkbox {
    span {
      @include setFont($size: 14px);
    }
  }

  .radio-buttons {
    margin: 10px 0;

    span {
      @include setFont($size: 14px);
    }
  }
}

.radio-buttons-without-form-control-label {
  @include flex();
  padding: 20px;

  .label {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
}

.page-header {
  position: relative;
  text-align: center;
  font-size: 14px;
  padding-top: 26px;
  padding-bottom: 14px;

  .arrow-back {
    position: absolute;
    left: 20px;
    cursor: pointer;
    color: $common-button-color;
  }

  h1 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: $common-button-color;
  }
}

.list-with-text {
  div {
    padding: 20px;

    span {
      color: $common-button-color;
      font-size: 16px;
    }
  }

  .arrow-in-list {
    float: right;
    width: 16px;
    color: $main-color;
  }
}

.pointer {
  cursor: pointer;
}

.App {
  .button-absolute {
    @include setAbsolute($bottom: 20px, $left: 0, $right: 0);
    @include setSpace($padding-top: 14px, $padding-right: 35px, $padding-bottom: 0, $padding-left: 35px);
    @include setAbsolute($left: 0, $right: 0, $bottom: 51px);
    text-align: center;
    width: 100%;
    position: absolute;

    button {
      max-width: 100%;
    }
  }

  .btn-google {
    background-color: $reddish;
    color: $white;
  }
  
  .question-container {
    .MuiInputBase-fullWidth {
      width: 90%;
    }
  }

  .early-access-form{
    padding: 15px 30px;

    .MuiFormControl-root{
      margin-bottom: 45px;

      p{
        margin: 0;;
      }
    }

    .MuiFormControlLabel-root{
      margin-top: 10px;
    }
  }

  .questions-label{
    margin-top: 30px;
    padding: 0 30px;
  }
  
  .setup-steps-container{
    .profile-background {
      background-image: url(./styles/images/backgrounds/recap-investing-close-up-environment-flora-HIRES.jpg);
      width: 100vw;
      height: 30vh;
      padding: 30px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  
      h1{
        margin: 0;
      }
  
      p{
        margin-bottom: 0;
      }
    }
  }

  .winning-row__wrapper{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .flex-space {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $background-color-1
  }

  .white-text {
    color: $white
  }

  .center-text {
    text-align: center;
  }

  .w-600 {
    font-weight: 600;
  }

  .winning-row {
    display: flex;
    align-items: center;
    flex-wrap: initial;
    margin-left: 31px;
    margin-right: 31px;
    color: $main-blue;
    font-weight: 600;

    svg.mr-20{
      min-width: 30px;
      max-width: 30px;
    }
  }

  .mr-20 {
    margin-right: 31px;
  }

  .MuiFormControlLabel-label {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.39;
    letter-spacing: 0.01px;
  }

  strong {
    color: $main-blue
  }

  .radio-button-with-2-lines-text {
    label {
      @include setSpace($margin-bottom: 7px, $margin-right: 0);
      @include flex($align-items: flex-start);

      .label {
        @include setSpace($padding-top: 8px);

        >div:nth-child(2) {
          @include setSpace($padding-right: 35px, $margin-top: 6px);
          @include setFont($size: 15px, $weight: 100, $color: $black);
          line-height: 1.2;
          letter-spacing: 0;
          opacity: 0.6;
        }
      }

      > span:nth-child(2) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 992px) {
  .App {
    .button-absolute {
      box-shadow: 6px 3px 6px 0 rgba(0, 0, 0, 0.16);
      @include setSpace($padding-bottom: 14px);
      @include setAbsolute($bottom: 0);
    }
  }

  .MuiPaper-elevation3 {
    box-shadow: 0px
  }
}
  
@media (min-width: 992px) {
  .setup-steps-container .MuiPaper-root {
    border-radius: 0px;
  }

}


