.mobile-menu-text {
  span {
    @include setFont($size: 14px);
  }
}

.sidebar-menu-items {
  text-align: center;

  .menu-item {
    display: flex;
    align-items: center;
    height: 52px;
    text-align: left;
    font-stretch: normal;
    letter-spacing: 0.05px;
    cursor: pointer;
    display: flex;
    @include setFont($size: 15px, $weight: 600, $style: normal, $color: $main-blue);
    @include setSpace(
      $padding-left: 20px,
      $padding-right: 20px,
      $padding-top: 0,
      $padding-bottom: 0,
      $margin-top: 14px,
      $margin-right: 14px,
      $margin-bottom: 14px,
      $margin-left: 14px
    );

    svg {
      height: auto;
      margin-right: 30px;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      background-color: $menu-buttons-hover-color;
      border-radius: 8px;
      width: auto;
      height: 50px;
      border-radius: 5px;
    }
  }
}

header {
  min-height: 57.2px;
  display: flex;

  .flexy {
    flex-grow: 1;
  }

  .logo {
    color: $main-white;

    .arrow-back {
      cursor: pointer;
      float: left;
      @include setSpace($margin-right: 20px, $padding-left: 5px);
      border-right: 1px solid $main-white;
      height: 35px;

      svg {
        @include setSpace($padding-right: 18px, $margin-top: 7px);
        height: 20px;
        width: auto;
      }
    }
  }
}
