$barlowFontFamily: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$baseSize: 16px;

//* Where the magic happens
//* Define the font for any element
@mixin setFont($family: $barlowFontFamily, $weight: false, $size: false, $color: false, $style: false) {
  font-family: $family;

  @if $weight {
    font-weight: $weight;
  }
  // bold defaults to font-weight 700, but with Roboto we want to use 600 instead
  @if $family == $barlowFontFamily {
    @if $weight == "bold" {
      $weight: 600;
    }
  }
  @if $size {
    font-size: calc-rem($size, $baseSize);
  }
  @if $color {
    color: $color;
  }
  @if $style {
    font-style: $style;
  }
}

@mixin setSpace(
  $margin-top: false,
  $margin-bottom: false,
  $margin-left: false,
  $margin-right: false,
  $padding-top: false,
  $padding-bottom: false,
  $padding-left: false,
  $padding-right: false
) {
  @if $margin-top {
    margin-top: calc-rem($margin-top, $baseSize);
  }
  @if $margin-bottom {
    margin-bottom: calc-rem($margin-bottom, $baseSize);
  }
  @if $margin-left {
    margin-left: calc-rem($margin-left, $baseSize);
  }
  @if $margin-right {
    margin-right: calc-rem($margin-right, $baseSize);
  }
  @if $padding-top {
    padding-top: calc-rem($padding-top, $baseSize);
  }
  @if $padding-bottom {
    padding-bottom: calc-rem($padding-bottom, $baseSize);
  }
  @if $padding-left {
    padding-left: calc-rem($padding-left, $baseSize);
  }
  @if $padding-right {
    padding-right: calc-rem($padding-right, $baseSize);
  }
}

@mixin setAbsolute($top: false, $right: false, $bottom: false, $left: false) {
  @if $top {
    top: calc-rem($top, $baseSize);
  }
  @if $right {
    right: calc-rem($right, $baseSize);
  }
  @if $bottom {
    bottom: calc-rem($bottom, $baseSize);
  }
  @if $left {
    left: calc-rem($left, $baseSize);
  }
}

// utility function, don't use this by itself
@function calc-rem($size, $baseSize) {
  @if $size != 0 and $size != auto {
    @return ($size/$baseSize) * 1rem;
  } @else {
    @return $size;
  }
}
