.MuiDialog-paperScrollPaper {
    color: $almost-black;

    .MuiDialogActions-root {
        margin: auto;
        width: 200px;

        .MuiButton-textPrimary {
            color: $main-white
        }
    }
}