.investfor-container {
  .header {
    text-align: center;
    @include setFont($size: 12px, $weight: lighter, $color: $almost-black, $style: normal);
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.01px;

    p {
      @include setSpace($margin-top: 25px);
      @include setFont($size: 18px, $weight: 100, $color: $black);
      line-height: 1.5;
    }

    svg {
      width: 100%;
      height: 5.25rem;
      padding: 10px 0 10px 0;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .saveFor, .period {
    @include setSpace($margin-top: 50px);
    width: 100%;

    p {
      @include setFont($size: 18px, $weight: 600, $color: $black);
    }

    .MuiInputBase-root {
      @include setFont($size: 18px, $weight: 400, $color: $main-blue);
      @include setSpace($padding-bottom: 10px);

      &::before {
        border-bottom: 1px solid $main-blue;
      }

      &::after {
        border-bottom: 2px solid $main-blue;
      }
    }

    .MuiSelect-icon {
      color: $main-blue;
    }
  }

  .period {
    @include setSpace($padding-bottom: 50px);
  }

  .slider-block {
    @include setSpace($margin-top: 40px, $margin-right: auto, $margin-bottom: 0, $margin-left: auto);
    display: flex;
    flex-direction: column;
    width: 90%;
  
    .slider-content {
      @include setFont($size: 17px, $weight: 600, $color: $main-blue);
      @include setSpace($margin-bottom: 10px);
      display: flex;
      justify-content: space-between;
    }
  }
  
  .slider {
    position: relative;
  
    &:before, &:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid $main-blue;
      content: "";
      position: absolute;
    }
  
    &:after {
      @include setSpace($margin-left: -7px);
    }
  }
  
}
