.impact-company-details {
  .MuiDialog-paper {
    border-radius: 20px;
    margin: 20px;
    
  }

  #impact-details-header {
    text-align: center;
    @include setSpace($padding-bottom: 15px, $padding-top: 25px);

    svg {
      @include setSpace($margin-bottom: 5px);
    }

    h4 {
      @include setFont($size: 23px, $weight: 600);
    }
  }

  #impact-details-holdings {
    @include setSpace($padding-top: 5px);

    h5 {
      @include setFont($size: 18px, $color: $main-blue, $weight: bold);
      line-height: 1.44;
      height: 22px;
    }

    p {
      @include setFont($size: 16px, $color: $almost-black);
      @include setSpace($margin-top: 12px, $margin-bottom: 12px);
      line-height: 1.38;
    }

    .impact-details-holdings-listing,
    .impact-details-holdings,
    .impact-details-recap {
      @include setSpace($padding-top: 10px);

      .holdings-title {
        border-bottom: solid 1px rgba(18, 58, 185, 0.16);
        height: 2rem;
      }
    }

    .holding {
      display: flex;
      @include setSpace($padding-top: 23.5px, $padding-bottom: 23.5px);
      border-bottom: solid 1px rgba(18, 58, 185, 0.16);
      @include setFont($size: 13px, $weight: bold);

      .company-ticker {
        flex: 1;

        span {
          background-color: $main-blue;
          color: $main-white;
          padding: 5px;
          line-height: 1.46;
        }
      }

      .company-name {
        flex: 3;
        text-align: left;
      }
    }
  }


  .impact-company-story {
    flex: 1;

    .company-logo {
      @include setSpace($margin-bottom: 30px, $margin-top: 30px); 
      flex: 1;
      display: flex;
      justify-content: center;

      img {
        width: 80%;
        max-height: 150px;
        max-width: 300px;
        object-fit: contain;
      }
    }
    .company-name {
      flex: 1;
      display: flex;
      justify-content: center;
      @include setSpace($margin-bottom: 9px); 
      @include setFont($size: 26px, $weight: 600);

      a {
        text-decoration: none;
        color: black;
      }
    }

    .company-ticker {
      flex: 1;
      display: flex;
      justify-content: center;

      a {
        background-color: $main-blue;
        color: $main-white;
        padding: 5px;
        line-height: 1.46;
        text-decoration: none;
      }
    }
    .headline {
      @include setFont($size: 16px, $color: $main-blue, $weight: bold);
      line-height: 1.44;
    }

    .see-also-links {
      display: flex;
      flex-direction: column;
      @include setSpace($margin-bottom: 9px); 
      h5 { 
        @include setFont($size: 16px, $color: $main-blue, $weight: bold);
        line-height: 1.44;
        height: 22px;
        margin-bottom: 0px;
      }
    }
  }

  .link-area {
    text-decoration: underline;
    justify-content: center;
    @include setSpace($padding-top: 9px, $padding-bottom: 9px);

    .link {
      color: $main-blue;
    }
  }

  .MuiMobileStepper-root {
    background: $main-white;
    @include setSpace($padding-top: 15px, $padding-bottom: 20px);

    .MuiMobileStepper-dot {
      @include setSpace($margin-right: 7px);
    }
  }

  .react-swipeable-view-container {
    height: 80vh;
    -webkit-overflow-scrolling: touch;

    .impact-swipable-item {
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      @include setSpace($padding-top: 15px, $padding-bottom: 15px, $padding-left: 15px, $padding-right: 15px);
    }
    .impact-swipable-item-content {
      flex: 1;
      margin-top: auto;
      margin-bottom: auto;
      min-height: 100px;
    }
  }
}
