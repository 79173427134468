@mixin flex(
  $direction: "row",
  $align-items: center,
  $justify-content: space-between,
  $flex-basis: false,
  $grow: false,
  $shrink: false
) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $direction;

  @if $flex-basis {
    flex-basis: $flex-basis;
  }

  @if $grow {
    flex-grow: $grow;
  }

  @if $shrink {
    flex-shrink: $shrink;
  }
}
