.impact-areas-container {
  @media screen and (max-width: 992px) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .header {
    text-align: left;
    @include setFont($size: 16px, $weight: lighter, $color: $almost-black, $style: normal);
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.01px;

    h3 {
      @include setFont($color: $almost-black);
      @include setSpace($padding-top: 16px, $padding-bottom: 8px);
    }

    @media screen and (max-width: 992px) {
      @include setSpace($padding-left: 20px);
    }
  }

  .impact-areas {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    @include setSpace($padding-bottom: 10px);

    .impact-area {
      justify-self: center;
      @include setSpace($padding-left: 4px, $padding-right: 4px, $padding-top: 12px, $padding-bottom: 12px);
      max-width: 150px;
      width: 100%;

      .impact-logo {
        cursor: pointer;
        background-color: $very-light-blue;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 76px;
        height: 76px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        &:focus {
          outline: none;
        }

        @media screen and (min-width: 768px) {
          width: 100px;
          height: 100px;
        }

        @media screen and (min-width: 992px) {
          width: 76px;
          height: 76px;
        }
      }

      .impact-logo-checked {
        background-color: $main-blue;

        svg {
          path,
          circle {
            fill: $main-white;
          }
        }
      }

      .impact-name {
        @include setSpace($margin-top: 9px);
        @include setFont($size: 12px, $weight: bold, $color: $black);
        text-align: center;
      }
    }
  }

  .impact-buttons-area {
    .selection-buttons {
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;

      .link {
        color: $main-blue;
        padding: 5px 0;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      @media screen and (max-width: 992px) {
        @include setSpace($padding-left: 20px);
      }
    }
    .area-details-block {
      position: relative;
      background-color: $very-light-blue;
      display: flex;
      align-items: center;
      margin-left: -1rem; 
      margin-right: -1rem;

      .area-details {
        @include setSpace($padding-top: 15px, $padding-bottom: 15px, $padding-left: 18px, $padding-right: 18px);
        @include setFont($size: 15px, $weight: 500, $color: $black);
        line-height: 1.2;
        letter-spacing: 0;
        text-align: center;
        width: 100%;
        overflow: hidden;

        .read-more {
          @include setFont($color: $main-blue, $weight: normal);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .link-area {
    text-decoration: underline;
    text-align: center;
    @include setSpace($padding-top: 9px, $padding-bottom: 9px);

    .link {
      color: $main-blue;
    }
  }
}
