.start-container {
  .header {
    text-align: center;
    @include setFont($size: 12px, $weight: lighter, $color: $almost-black, $style: normal);
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.01px;

    p {
      @include setSpace($margin-top: 25px);
      @include setFont($size: 20px, $weight: 100, $color: $black);
      line-height: 1.5;
    }

    svg {
      width: 100%;
      height: 5.25rem;
      padding: 10px 0 10px 0;
      margin-top: 50px;
      margin-bottom: 10px;
    }
  }

  .MuiFormControl-root {
    @include setSpace($margin-top: 50px);
    width: 100%;

    p {
      @include setFont($size: 18px, $weight: 600, $color: $black);
    }

    .MuiInputBase-root {
      @include setFont($size: 18px, $weight: 400, $color: $main-blue);
      @include setSpace($padding-bottom: 10px);

      &::before {
        border-bottom: 1px solid $main-blue;
      }

      &::after {
        border-bottom: 2px solid $main-blue;
      }
    }

    .MuiSelect-icon {
      color: $main-blue;
    }
  }
}
