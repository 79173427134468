.projections-container {
  background-color: $very-light-blue-2;
  @include setSpace($padding-top: 30px)

  h3 {
    @include setSpace($margin-left: 38px, $margin-right: 20px);
    @include setFont($size: 22px, $weight: 600);
    line-height: 1.18;
  }

  .likelihood {
    text-align: center;

    h5 {
      @include setFont($size: 20px, $weight: 600, $color: $main-blue);
      @include setSpace($padding-bottom: 8px, $margin-bottom: 0);
      line-height: 1.3;

      @media screen and (max-width: 992px) {
        width: 220px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    h4 {
      @include setFont($size: 26px, $weight: bold, $color: $main-blue);
      @include setSpace($padding-top: 8px, $margin-top: 0);
    }
  }

  .info-icon {
    @include setFont($color: $main-blue);
    width: 14.5px;
    height: 14.5px;
  }

  .with-background {
    @include setSpace($padding-right: 0, $padding-left: 30px);
    user-select: none;
  }

  .info-block {
    @include setFont($size: 16px, $weight: 500, $color: $agreement-color);
    text-align: center;
    line-height: 1.56;

    .link {
      text-decoration: underline;
      color: $main-blue;
      margin: 0;
    }
  }

  .deposit-footer {
    @include setFont($size: 16px, $weight: 500, $color: $agreement-color);
    @include setSpace($margin-top: 0px);
    text-align: center;
    line-height: 1.56;

    p {
      @include setSpace($margin-top: 0px, $margin-bottom: 0px);
    }
  }

  .controls {
    @include setSpace($margin-top: 25px, $padding-bottom: 50px, $padding-left: 20px, $padding-right: 20px);

    .MuiFormControl-root {
      width: 100%;

      .MuiFormControlLabel-root {
        align-items: start;

        .MuiFormControlLabel-label {
          @include setFont($size: 22px, $weight: 600);
          line-height: 2;
        }
      }
    }
  }
}

.recharts-default-tooltip {
  @include setFont($size: 13px, $color: $almost-black);
  border-radius: 5px;
  border-color: $main-blue !important;
  ul li {
    @include setFont($color: $almost-black!important);
  }
}
