.loader-block {
  background-color: #F4F4F4;
  height: 100vh;

  .loader {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    color: #585858;
  }

  &.loader-fixed {
    background-color: $main-white;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10000;
    padding: 10px;
    text-align: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    .loader {
      position: static;
      color: $main-blue;
      width: 40px;
      height: 40px;
    }
  }
}
