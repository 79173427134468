$common-button-color: #585858;
$main-color: #7D7D7D;
$home-background: #F4F4F4;
$main-blue: #123AB9;
$home-blue: #275083;
$main-yellow: #FFCE00;
$footer-yellow: #f5b300;
$button-hover: #EAB003;
$main-white: #ffffff;
$google-color: #cf4332;
$almost-black: #0e1517;
$black: #000000;
$white:  #ffffff;
$agreement-color: #575757;
$menu-buttons-hover-color: #e2e7f7;
$very-light-blue: #e3f0fd;
$very-light-blue-2: #f4f9ff;
$age-error: #9e9e9e;
$background-color-1: #f6faff;
$very-light-blue: #e3f0fd;
$very-light-blue-2: #f4f9ff;
$headerHeight: 57.2px;
$progressBarHeight: 8px;
$disclosure-text-color: #707070;
$scroll-color: #eeeeee;
$light-grey: #797979;
$green: #67D484;
$very-light-pink: #efefef;
$reddish:#cf4331;
