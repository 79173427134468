.setup-steps-container {
  padding: 0;
  position: relative;

  .question-container {
    z-index: 1;

    @include setSpace($padding-top: 0, $padding-right: 35px, $padding-left: 35px, $padding-bottom: 80px);
    min-height: calc(100vh - #{$headerHeight + $progressBarHeight});
    position: relative;
  }

  .onboarding-button {
    position: absolute;
    @include setAbsolute($left: 0, $right: 0);
    text-align: center;
    z-index: 2;

    .MuiButton-root {
      min-height: 45px;
      max-width: 100%;
    }
  }
}

.onboarding-header {
  @include setFont($size: 22px, $weight: bold, $color: $almost-black, $style: normal);
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: 0.01px;
  @include setSpace($margin-top: 0, $margin-bottom: 42px);
}

@media (max-width: 992px) {
  .setup-steps-container {
    .onboarding-button {
      box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.08);
      background-color: $main-white;
      position: fixed;
      @include setAbsolute($bottom: 0);
      @include setSpace($padding-top: 12px, $padding-right: 35px, $padding-bottom: 12px, $padding-left: 35px);
    }

    .MuiPaper-root {
      box-shadow: none;
    }
  }
}

@media(min-width: 768px) {
  .setup-steps-container {
    .question-container {
      @include setSpace($padding-top: 25px);
    }
  }
}

@media (min-width: 992px) {
  .setup-steps-container {
    .question-container {
      margin: 0 auto;
      max-width: 70%;
      @include setSpace($padding-top: 25px, $padding-bottom: 25px);

      > .MuiPaper-root {
        height: 48rem;
        max-height: calc(100vh - 115px);
        overflow-y: auto;
        @include setSpace($padding-top: 30px, $padding-right: 100px, $padding-left: 100px, $padding-bottom: 30px);
      }
    }

    .onboarding-button {
      position: relative;
      @include setSpace($padding-top: 15px);
      margin-top: auto;
    }

    .MuiPaper-root {
      @include setSpace($padding-top: 30px, $padding-right: 30px, $padding-left: 30px, $padding-bottom: 30px);
      border-radius: 1rem;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: 992px) {
  .setup-steps-container {
    .question-container {
      max-width: 900px;
    }
  }
}
