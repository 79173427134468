.results-container.question-container {
  @include setSpace($padding-left: 0, $padding-right: 0, $padding-bottom: 0);
  max-width: 100%;

  >.MuiPaper-root {
    overflow-x: hidden;
  }

  .portfolio-chart {
    @include setSpace($padding-left: 15px, $padding-right: 15px);
  }

  .portfolio-controls {
    background-color: $very-light-blue-2;

    .MuiFormControl-root {
      display: flex;
      justify-content: flex-end;
      @include setSpace($margin-bottom: 0, $padding-bottom: 20px);

      .MuiFormControlLabel-labelPlacementStart {
        @include setSpace($margin-right: 8px, $margin-left: 8px);
      }

      .MuiInputBase-root {
        flex: auto;

        .MuiSelect-select {
          @include setFont($size: 17px, $color: $main-blue, $weight: 600);
          background-color: $very-light-blue-2;
          white-space: normal;
          justify-content: flex-end;
        }

        .MuiSelect-icon {
          color: $main-blue;
        }

        &::before {
          border-bottom: none;
        }
      }

      .MuiTypography-root {
        @include setFont($size: 17px);
        flex: auto;
      }

      .MuiSlider-root {
        max-width: 12.5rem;
      }
    }
  }

  .current-step-wrapper-inline,
  .onboarding-header,
  .holdings,
  .companies-header,
  .portfolio-model,
  .company,
  .total {
    @include setSpace($padding-left: 35px, $padding-right: 35px);
  }

  .onboarding-header {
    @include setSpace($margin-bottom: 22px, $margin-top: 30px);

    h2 {
      @include setFont($size: 22px);
    }
  }

  .portfolio-model {
    @include setFont($size: 20px, $color: $main-blue, $weight: bold);
    @include setSpace($padding-bottom: 23px);
    text-align: center;
  }

  .strategy {
    @include setSpace($padding-top: 20px, $padding-bottom: 20px, $padding-left: 35px, $padding-right: 35px);
    background-color: $very-light-blue-2;

    .onboarding-header {
      @include setSpace($padding-left: 0px, $padding-right: 0px);
    }

    .MuiButton-label {
      justify-content: left;
    }

    span {
      text-align: left;
      outline: solid 1px;
    }
  }

  .with-yellow-circle {
    @include setFont($size: 15px, $weight: bold, $color: $almost-black);
    position: relative;

    &:before {
      @include setAbsolute($left: -20px, $top: 3px);
      @include setSpace($margin-right: 10px);
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 7.5px;
      background-color: $main-yellow;
    }
  }

  .equity {
    @include setSpace($padding-left: 30px, $padding-right: 30px, $margin-top: 40px);

    > div:nth-child(1) {
      @include setFont($size: 15px, $weight: bold, $color: $almost-black);
      position: relative;

      .active-asset-circle {
        @include setAbsolute($left: -20px, $top: 3px);
        @include setSpace($margin-right: 10px);
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 7.5px;
        background-color: currentColor;
      }
    }

    div:last-child {
      @include setSpace($margin-top: 5px);
      @include setFont($size: 15px, $color: $almost-black);
      line-height: 1.6;
      letter-spacing: normal;
    }
  }

  .chart {
    @include setFont($size: 13px, $weight: bold, $color: $black);
    line-height: 3.08;
    letter-spacing: normal;
    display: flex;

    .percents {
      text-align: center;
    }

    .colored-block {
      cursor: pointer;
      height: 23px;
      background-color: deeppink; //non-design color to catch unexpected blocks in portfolio chart.
    }

    .active-asset {
      height: 35.5px;
    }
  }

  .MuiMobileStepper-root {
    background: $main-white;
    @include setSpace($padding-top: 15px, $padding-bottom: 20px);

    .MuiMobileStepper-dot {
      @include setSpace($margin-right: 7px);
    }
  }

  .MuiInput-underline {
    &::after {
      content: none;
    }
  }

  .portfolio-controls {
    @include setSpace($padding-top: 30px, $padding-left: 15px, $padding-right: 15px);
  }

  .company-listings {
    background-color: $very-light-blue-2;
    @include setSpace($padding-bottom: 72px, $padding-left: 20px, $padding-right: 20px);

    .MuiPaper-root {
      @include setSpace($margin-bottom: 50px);
      box-shadow: 5px 5px 10px 0 rgba(18, 58, 185, 0.3);
      border-radius: 10px;
      background-color: $main-white;

      .asset-header h3 {
        @include setFont($size: 22px);
        @include setSpace($padding-top: 28px, $padding-left: 22px, $padding-bottom: 25px);
      }

      .MuiDivider-middle {
        background-color: $main-blue;
        @include setSpace($margin-left: 22px, $margin-right: 22px, $margin-bottom: 22px);
      }

      .listing {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        
        .impact-icon {
          flex: 1;
          text-align: center;

          svg {
            width: 2rem;
          }
        }

        .impact-details {
          flex: 2;
          @include setSpace($margin-bottom: 22px);

          h4 {
            @include setFont($size: 19px, $color: $main-blue, $weight: bolder);
            @include setSpace($padding-top: 5px, $padding-bottom: 5px);
            margin: 0;
          }

          p {
            @include setFont($size: 18px, $color: $almost-black, $weight: 600);
            @include setSpace($padding-top: 5px, $padding-bottom: 5px);
            margin: 0;
          }
        }

        .open-impact {
          display: flex;
          justify-content: center;          
        }
      }
    }

    .held-for-diversification {
      margin-bottom: 0;
    }
  }

  .footer {
    .disclosures {
      @include setSpace($padding-bottom: 35px, $padding-top: 40px, $padding-left: 35px, $padding-right: 35px);
      background-color: $main-yellow;
      h3 {
        @include setFont($size: 22px, $weight: 600);
        height: 26px;
        line-height: 0.82;
      }

      p {
        @include setFont($size: 16px);
        line-height: 1.88;
      }
    }
    .help {
      @include setSpace($padding-bottom: 37px, $padding-top: 30px, $padding-left: 20px, $padding-right: 20px);
      background-color: $footer-yellow;
      display: flex;
      text-align: left;
      align-items: center;

      svg {
        flex: 1;
      }

      h4 {
        flex: 4;
        @include setFont($size: 20px, $weight: 600);
        line-height: 1.1;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .portfolio-controls {
      @include setSpace($padding-top: 62px, $padding-left: 30px, $padding-right: 30px);
    }
  }

  @media screen and (min-width: 992px) {
    max-width: 80%;
    @include setSpace($padding-top: 25px, $padding-bottom: 25px);
    >.MuiPaper-root {
      height: auto;
      max-height: none;
      overflow-x: visible;
      @include setSpace($padding-top: 0, $padding-bottom: 0, $padding-left: 30px, $padding-right: 30px);
    }
    // large screen
    .MuiMobileStepper-root {
      height: auto;
      width: auto;
      margin: 0;
    }

    .portfolio-controls, .projections-container, .footer.disclosures, .footer.help {
      @include setSpace($padding-left: 30px, $padding-right: 30px, $margin-left:-30px, $margin-right: -30px);
    }

    .footer {
      @include setSpace($margin-left:-30px, $margin-right: -30px);
    }

    .company-listings {
      @include setSpace($padding-bottom: 72px, $padding-left: 30px, $padding-right: 30px, $margin-left: -30px, $margin-right: -30px );
    }
  }

  @media screen and (min-width: 1200px) {
    max-width: 900px;
  }
}

.impact-dollars {
  @include setSpace($padding-left: 20px, $padding-right: 20px);

  &-value {
    @include setSpace($margin-top: 20px, $margin-bottom: 20px);
    @include setFont($size: 32px, $color: $main-blue, $weight: bold);
    text-align: center;
    

    .label {
      @include setFont($size: 16px, $weight: normal);
    }
  }

  strong {
    color: $main-blue;
  }

  @media screen and (min-width: 768px) {
    @include setSpace($padding-left: 0, $padding-right: 0);
  }
}

.impact-company-details {
  strong {
    color: $main-blue;
  }
}

.value-prop {
  @include setSpace($padding-left: 20px, $padding-right: 20px, $padding-top: 20px, $padding-bottom: 30px);
  text-align: center;

  h2 {
    @include setFont($size: 24px);
    color: $main-blue;
  }

  strong {
    color: $main-blue;
  }

  @media screen and (min-width: 768px) {
    @include setSpace($padding-left: 0, $padding-right: 0);
  }
}
