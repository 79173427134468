.page-not-found-with-header, .page-not-found-without-header {
  position: relative;
  max-width: 414px;
  @include setSpace($margin-top: 0, $margin-right: 0, $margin-bottom: 0, $margin-left: 239px,
  $padding-top: 35px, $padding-right: 35px, $padding-bottom: 100px, $padding-left: 35px);
  min-height: calc(100vh - #{$headerHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header-404 {
    @include setSpace($margin-top: 25px);
    @include setFont($size: 60px, $weight: bold, $color: $almost-black);
  }

  .header-text-404 {
    @include setFont($size: 22px, $weight: bold, $color: $almost-black);
  }

  .message-404 {
    @include setSpace($margin-top: 28px);
    @include setFont($size: 22px, $weight: normal, $color: $almost-black);
    max-width: 265px;
    text-align: center;
  }
}

.page-not-found-without-header {
  min-height: 100vh;
}

@media(max-width: 992px) {
  .page-not-found-with-header, .page-not-found-without-header {
    @include setSpace($margin-top: 0, $margin-bottom: 0, $margin-left: 0, $margin-right: 0);
  }
}
